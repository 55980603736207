/* eslint-disable no-lone-blocks */
import { React, useRef, useState, useEffect } from 'react';
import './index.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import './cards.css'
import Item from '../components/items';
import Footer from '../components/footer/footer';
import Contact from '../components/contact/contact';
import ImageCard from '../components/imageCard/ImageCard';


export default function Index() {
  const contactRef = useRef(null);
  const productRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);


  useEffect(() => {
    // Function to update the width
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  // Scroll to the Contact section
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToProduct = () => {
    productRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Set the breakpoint for mobile detection

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Add event listener on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>

      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" sticky='top'>
        <Container>
          <Navbar.Brand href="#home">
            <h1 className="title">
              <span>Utkarshaa Food & Dairy Products</span>
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={scrollToProduct} style={{ fontSize: 18, fontWeight: '700' }}>Products</Nav.Link>
              {/* Use onClick instead of href for smooth scrolling */}
              <Nav.Link onClick={scrollToContact} style={{ fontSize: 18, fontWeight: '700' }}>Contact</Nav.Link>
            </Nav>
            <Nav className='imagesNav'>
              <img src={require('../Images/logo.png')} alt='Logo' className='Logo' style={{ marginRight: '20px' }} />
              <img src={require('../Images/sublogo.png')} alt='Logo' className='SubLogo' />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <Carousel id="Products" interval={2000}>
        <Carousel.Item>
          <img
            style={{
              height: isMobile ? '60vh' : '85vh', // Set different heights for mobile and desktop
              objectFit: 'fill',
              borderRadius: '15px'
            }}
            className="d-block w-100"
            src={isMobile ? require('../Images/MobileFirst.jpeg') : require('../Images/FirstPage.jpeg')}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{
              height: isMobile ? '60vh' : '85vh', // Set different heights for mobile and desktop
              objectFit: 'fill',
              borderRadius: '15px'
            }} className="d-block w-100"
            src={isMobile ? require('../Images/mobile1.png') : require('../Images/asset1.png')}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Gulkand Basundi</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{
              height: isMobile ? '60vh' : '85vh', // Set different heights for mobile and desktop
              objectFit:'fill',
              borderRadius: '15px'
            }} className="d-block w-100"
            src={isMobile ? require('../Images/mobile2.png') : require('../Images/asset2.png')}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h5>Anjeer Basundi</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{
              height: isMobile ? '60vh' : '85vh', // Set different heights for mobile and desktop
              objectFit: 'fill',
              borderRadius: '15px'
            }} className="d-block w-100"
            src={isMobile ? require('../Images/mobile3.png') : require('../Images/asset3.png')}
            alt="Fourth slide"
          />
          <Carousel.Caption>
            <h5>Rabbdi Basundi</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{
              height: isMobile ? '60vh' : '85vh', // Set different heights for mobile and desktop
              objectFit: 'fill',
              borderRadius: '15px'
            }}
            className="d-block w-100"
            src={isMobile ? require('../Images/mobile4.png') : require('../Images/asset4.png')}
            alt="Fifth slide"
          />
          <Carousel.Caption>
            <h5>Shitapal Basundi</h5>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <h1 ref={productRef} id='Products' style={{ textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: '700' }}>Our Special Products</h1>
      <div className='Cards' >
        <Card style={{ width: '17rem' }}>
          <Card.Img className='singleImg' variant="top" src={require('../Images/mobile1.png')} />
          <Card.Body>
            <Card.Title className='title'>Gulkand Basundi </Card.Title>
            <Card.Text>
              <Item />
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ width: '17rem', }}>
          <Card.Img variant="top" src={require('../Images/mobile2.png')} />
          <Card.Body>
            <Card.Title className='title'>Anjeer Basundi </Card.Title>
            <Item />
          </Card.Body>
        </Card>

        <Card style={{ width: '17rem', }}>
          <Card.Img variant="top" src={require('../Images/mobile3.png')} />
          <Card.Body>
            <Card.Title className='title'>Rabbdi   Basundi </Card.Title>
            <Item />
          </Card.Body>
        </Card>

        <Card style={{ width: '17rem', }}>
          <Card.Img variant="top" src={require('../Images/mobile4.png')} />
          <Card.Body>
            <Card.Title className='title'>Shitapal Basundi </Card.Title>
            <Item />
          </Card.Body>
        </Card>

      </div>
      <h1 style={{ textAlign: 'center', marginTop: '30px', marginBottom: '20px', color: 'black', fontWeight: '700' }}>Our Products</h1>
      <div className='ImageContainer'>
        <ImageCard imgPath={require('../Images/p1.jpg')} />
        <ImageCard imgPath={require('../Images/amar.jpg')} />
        <ImageCard imgPath={require('../Images/p3.jpg')} />
        <ImageCard imgPath={require('../Images/p4.jpg')} />
        <ImageCard imgPath={require('../Images/p5.jpg')} />
      </div>

      <div ref={contactRef} id="Contact">
        <Contact />
      </div>

      <Footer />


    </>
  );
}

