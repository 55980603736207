  import Index from './spages/Index';
  import './App.css'


function App() {
  return (
    <div>
    <Index/>
    </div>
  );
}

export default App;
