/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


export default function footer() {
  return (
    <div>
<footer class="footer-distributed">

			<div class="footer-left">

				<img className='Logo' src={require('./logo.png')} alt='Logo'/>
                <img className='SubLogo' src={require('./sublogo.png')} alt='Logo'/>

				<p class="footer-links">
					<a href="#" class="link-1">Home</a>
					
					<a href="#">Products</a>
				
					<a href="#">About</a>
					
					<a href="#">Contact</a>
				</p>

				<p class="footer-company-name">Company Name © 2015</p>
			</div>

			<div class="footer-center">

				<div>
				<svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#5f6368"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>					
				<p><span>Plot no 42 Layout 01</span> <span>Baba Jagar Nagar, Karveer</span> <span>Kolhapur Maharashtra 416007</span></p>
				</div>

				<div>
				<svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#5f6368"><path d="M796-120q-119 0-240-55.5T333-333Q231-435 175.5-556T120-796q0-18.86 12.57-31.43T164-840h147.33q14 0 24.34 9.83Q346-820.33 349.33-806l26.62 130.43q2.05 14.9-.62 26.24-2.66 11.33-10.82 19.48L265.67-530q24 41.67 52.5 78.5T381-381.33q35 35.66 73.67 65.5Q493.33-286 536-262.67l94.67-96.66q9.66-10.34 23.26-14.5 13.61-4.17 26.74-2.17L806-349.33q14.67 4 24.33 15.53Q840-322.27 840-308v144q0 18.86-12.57 31.43T796-120ZM233-592l76-76.67-21-104.66H187q3 41.66 13.67 86Q211.33-643 233-592Zm365.33 361.33q40.34 18.34 85.84 29.67 45.5 11.33 89.16 13.67V-288l-100-20.33-75 77.66ZM233-592Zm365.33 361.33Z"/></svg>				
					<p>+91 9935379191</p>
				</div>

				<div>
				<svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#5f6368"><path d="M146.67-160q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67ZM480-454.67 146.67-670v443.33h666.66V-670L480-454.67Zm0-66.66 330.67-212H150l330 212ZM146.67-670v-63.33V-226.67-670Z"/></svg>					<p><a href="mailto:support@company.com">support@company.com</a></p>
				</div>

			</div>

			<div class="footer-right">

				<p class="footer-company-about">
					<span>About the company</span>
					
India's first well-known natural flavored and healthy Jaggery Basundi
				</p>

				<div class="footer-icons">

					<a href="https://www.facebook.com/urvijbasundifb/"><FontAwesomeIcon icon={faFacebook} size="2x" /></a>
					<a href="https://www.linkedin.com/company/urvij-basundi/"><FontAwesomeIcon icon={faLinkedinIn} size="2x" /></a>
					<a href="https://x.com/urvijbasundi"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
					<a href="https://www.instagram.com/urvijbasundi/"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>

				</div>

			</div>

		</footer>
    </div>
  )
}
