/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import './contact.css'

export default function contact() {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [msg, setMsg] = useState("");

  function handleClick (){
   console.log("Clicked", name,number,msg)
  }



  return (
    <div>
        <div className='ContactForm'>
        <h1 style={{color:'black', fontWeight:'900'}}>Contact</h1>
        <div className='Inputs'>
            <input type='text' className='input' placeholder='Name' onChange={setName}/>
            <input className='input' type='numeric' placeholder='Number' onChange={setNumber}/>
            <textarea  className='input' type='text' placeholder='Message' onChange={setMsg}/>
            <button className='btn' onClick={handleClick()}>Send Enquiry</button>
        </div>

        </div>
      
    </div>
  )
}
